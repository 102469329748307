<template>
    <!--Details Container-->
    <ContentLayout custom-class="flex-col">
        <PageHeadline>Technische Details</PageHeadline>
        <div class="flex flex-col w-full text-left text-gray7 text-lg">
            <h2 class="text-white text-2xl underline mt-4">cybo-system</h2>
            <p>
                VESA 75 mount point
                <br />cybo-Halterung aus hochwertigem Metall gefertigt <br />Netzwerkeinbindung via
                LAN, WLAN <br />Einfache Drucker Konfiguration: kompatibel mit LAN, USB oder
                serieller Schnittstelle
            </p>

            <h2 class="text-white text-2xl underline mt-4">Touchscreen</h2>
            <p>
                7″ kapazitiver LCD Touchscreen Bildschirm
                <br />800x480 pixel <br />Diagonale 17,78 cm
            </p>

            <h2 class="text-white text-2xl underline mt-4">NFC</h2>
            <p class="mb-4">
                NFC Tag, Kompatibel mit Android und iOS <br /><span class="pl-4"
                    >Android nativ ab Android 6</span
                >
                <br /><span class="pl-4">ab iOS 13 nutzbar</span> <br /><span class="pl-8"
                    >ab iPhone X nativ</span
                >
                <br /><span class="pl-8">iPhone 7-9 NFC Reader app notwendig</span> <br /><span
                    class="pl-8"
                    >iPhone 1-6 Kein NFC support</span
                >
                <br />Lese Abstand: 2-8cm <br />54mm x 85.6mm x 8mm
            </p>
        </div>
    </ContentLayout>
</template>

<script>
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentLayout from '@/components/Layout/ContentLayout'
export default {
    name: 'TechnischeDetails',
    components: { ContentLayout, PageHeadline },

    meta() {
        return {
            title: 'Technische Details',
        }
    },
}
</script>

<style></style>
