<template >
    <div class="w-full">
        <figure class="w-full relative top-1">
            <img src="@/assets/svg/zettel.svg" alt="receipt tear-off edges" />
        </figure>
        <section class="bon-shadow flex flex-col items-center w-full bg-gradient-r-main">
            <header class="flex flex-col items-center w-full">
                <img src="@/assets/svg/cybo-logo_style3.svg" alt="" class="my-4" />
                <!--
                <hr class="bg-GSBFC8D5 border-0 h-2 w-5/6 md:w-content rounded-3" />
                -->
                <div class="text-BS002E62 text-2xl mb-10">Ihr digitaler Kassenbon</div>
                <div class="w-full px-10">
                    <div class="border-b-4 border-BS002E62 border-dashed w-full"></div>
                </div>
            </header>

            <div class="w-5/6 md:w-content text-left">
                <MainArticle
                    v-for="article in story"
                    :key="article.id"
                    :headline="article.headline"
                    :text="article.text"
                >
                </MainArticle>
            </div>
        </section>
        <figure class="w-full relative -top-1">
            <img src="@/assets/svg/zettel_down.svg" alt="receipt tear-off edges" />
        </figure>
    </div>
</template>

<script>
import MainArticle from '@/components/Main/MainArticle'

export default {
    name: 'MainStory',
    components: {
        MainArticle,
    },
    data() {
        return {
            story: [
                {
                    id: 0,
                    headline: '',
                    text:
                        'Bieten Sie Ihren Kunden die Möglichkeit die Belegausgabepflicht einfach und nachhaltig zu erfüllen. ' +
                        'Sparen Sie dabei auch noch Zeit und Kosten, reduzieren Sie Umweltbelastungen durch den Verzicht auf ' +
                        'umweltschädliches Thermopapier.',
                },
                {
                    id: 1,
                    headline: 'Einfache Einrichtung',
                    text:
                        'Es sind keine Veränderungen an der bestehenden Kassensoftware oder -hardware erforderlich. <br> Das cybo-system kann in jedes System mit einem externen Drucker integriert werden.',
                },
                {
                    id: 2,
                    headline: 'Digitale Übertragung',
                    text:
                        'Der QR-Code ist die einfachste Möglichkeit für die digitale Übertragung: Kamera App öffnen, ' +
                        'QR-Code scannen, fertig.<br> Alternativ ist eine schnelle Übertragung per optionalem NFC-Modul ' +
                        'möglich.<br> Es ist keine Registrierung, kein Konto und keine App erforderlich, um unseren Service zu nutzen.',
                },
                {
                    id: 3,
                    headline: 'Vorteile für cybo-Kunden',
                    text:
                        'Die Aushändigung der digitalen Belege erfolgt unmittelbar, unkompliziert und kontaktlos direkt ' +
                        'an den Endkunden.<br> Gleichzeitig minimiert sich der Papierverbrauch und die damit verbundenen ' +
                        'Lagerkosten sowie Entsorgungskosten überflüssig gedruckter Bons.',
                },
                {
                    id: 4,
                    headline: 'Vorteile für Endkunden',
                    text:
                        'Die digitalen Belege lassen sich einfach speichern, archivieren und löschen.<br> Sie sind auch als Bild- oder PDF-Datei teilbar.<br> Einfaches Verwalten von wichtigen Belegen für Steuererklärungen, Garantieansprüche, Spesenabrechnungen oder Ähnliches.',
                },
                {
                    id: 5,
                    headline: 'Gut für die Umwelt',
                    text:
                        'Gemeinsam Papier einsparen durch zeitgemäße Technik.<br> Weniger Kosten für Papier und ein Plus für die Umwelt.',
                },
                {
                    id: 6,
                    headline: 'Erfüllt die Bonausgabepflicht',
                    text:
                        'Cybo erfüllt alle gesetzlichen Anforderungen der ab 01.01.2020 geltenden Belegausgabepflicht.',
                },
            ],
        }
    },
}
</script>
<style scoped>
.bon-shadow {
    box-shadow: 0px 0px 30px 1px rgb(0 46 98 / 20%);
}
</style>