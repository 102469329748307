<template>
    <h2 class="text-lg text-gray9 my-1">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'PageSubHeadline',
}
</script>
<style></style>