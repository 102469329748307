<template >
    <div class="w-full">
        <figure class="w-full relative top-1">
            <img src="@/assets/svg/zettel.svg" alt="receipt tear-off edges" />
        </figure>

        <section class="flex flex-col items-center w-full bg-gradient-r-main py-4">
            <header class="flex flex-col items-center w-full pb-8">
                <h2 class="text-center text-40 text-BS002E62">Partner</h2>
            </header>
            <a class="w-1/3" href="https://dds-daon.de/" target="_blank"
                ><img
                    class="mx-auto"
                    src="@/assets/images/dds-logo.png"
                    alt="logo of daon data systems gmbh"
            /></a>

            <a
                class="w-5/6 py-8 text-gray2 text-lg md:text-xl md:w-content text-center"
                href="https://dds-daon.de/"
                target="_blank"
                >Daon Data Systems GmbH</a
            >
            <p
                class="w-5/6 font-nunito-light md:w-content text-left leading-normal text-16 md:text-18"
            >
                Mit dem Know-how unseres Partners konnten wir cybo gezielt für die spezifischen
                Anforderungen der aktuellen Infrastruktur im Kassenmarkt entwickeln. Unser Partner
                DDS - Daon Data Systems GmbH hat mehr als 30 Jahre Erfahrung und Kompetenzen im
                Kassenmarkt, sowohl als Hersteller wie auch als Softwareentwickler und Distributor.
            </p>
        </section>

        <figure class="w-full relative -top-1">
            <img src="@/assets/svg/zettel_down.svg" alt="receipt tear-off edges" />
        </figure>
    </div>
</template>

<script>
export default {
    name: 'MainPartner',
}
</script>
<style scoped></style>