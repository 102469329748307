<template>
    <section
        class="flex flex-row items-center mx-auto max-w-6xl pb-8 xl:pb-0"
        :class="[...customClass]"
    >
        <slot></slot>
    </section>
</template>

<script>
export default {
    name: 'ContentLayoutContact',
    props: {
        customClass: { type: String, required: false, default: '' },
    },
}
</script>

<style></style>

