<template>
    <div
        class="flex flex-col mx-auto w-10/12 my-4 bg-white rounded-6 shadow-custom sm:flex-row sm:w-full"
    >
        <div class="flex flex-col w-full sm:w-5/12 justify-center">
            <img
                class="rounded-t-6 sm:rounded-none sm:rounded-l-6"
                :src="require('@/assets/images/' + imgName)"
                :alt="'Hello its me ' + name"
            />
        </div>
        <div class="flex flex-col sm:w-7/12 text-left px-4 py-3">
            <h2 class="underline text-BS002E62 text-lg">{{ name }}</h2>
            <p class="text-primary py-1 mt-4">{{ position }}</p>
            <p class="text-BS002E62 py-1 mt-2">{{ functions }}</p>
        </div>
    </div>
</template>


<script>
export default {
    name: 'TeamCard',
    props: {
        imgName: { type: String, required: true },
        name: { type: String, required: true },
        position: { type: String, required: true },
        functions: { type: String, required: true },
    },
}
</script>
<style></style>
