<template>
    <div
        class="flex flex-col items-center mx-auto w-5/6 sm:w-3/5 py-2 xl:flex-row xl:py-0 xl:w-1/2"
    >
        <!-- Left -->
        <div class="xl:w-1/2 py-2 xl:py-0 sm:pr-2">
            <p class="text-center xl:text-right text-BS002E62 text-xs">
                Abonnieren Sie unseren Newsletter, um aktuelle Informationen zu erhalten
            </p>
        </div>
        <!-- Right -->
        <form
            class="flex w-full relative xl:w-1/2 items-center"
            @submit.prevent="registerNewsletter"
        >
            <input
                v-model="email"
                class="abo bg-g pl-2 w-full h-10 rounded-6 bg-transparent"
                placeholder="Ihre Emailadresse"
                type="email"
                required
                :disabled="state !== 0"
            />

            <button
                v-if="state === 0"
                class="absolute right-0 h-8 mr-1 px-2 py-1 rounded-6 text-g bg-secondary hover:bg-primary active:bg-secondary"
                type="submit"
            >
                <div v-if="isLoading">
                    <svg
                        class="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
                <div v-else>Abonnieren</div>
            </button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'Newsletter',
    data() {
        return {
            state: 0,
            isLoading: false,
            email: '',
        }
    },
    methods: {
        registerNewsletter: function () {
            this.isLoading = true

            let context = this
            this.$http
                .post('/website/registerNewsletter', {
                    email: context.email,
                })
                .then(function (response) {
                    context.isLoading = false
                    if (response.status === 200) {
                        console.log('Newsletter Response')
                        context.state = 1
                        context.email = 'Vielen Dank!'
                    } else {
                        console.log('Newsletter Failure')
                        console.log(context)
                        context.state = 2
                        context.email = 'Fehler :('
                    }
                })
                .catch(function (err) {
                    context.isLoading = false
                    console.log(err)
                })
        },
    },
}
</script>

<style scoped>
.abo {
    font-size: 0.875rem;
    padding-right: 6.75rem;
}
input:focus {
    box-shadow: 0 0 1px 1px theme('colors.secondary');
    outline: none;
}
</style>
