import Vue from 'vue'
import App from '@/App.vue'
const constants = require('@/lib/constants')

//SEO
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    keyName: 'meta',
})
Vue.config.productionTip = false
//CSS
import '@/assets/css/tailwind.css'

// plugin for country flags
//import FlagIcon from 'vue-flag-icon';
//Vue.use(FlagIcon);

let VueCookies = require('vue-cookies')
Vue.use(VueCookies)
Vue.prototype.$CONSENT = 'CYBO.CONSENT.USER'

import router from '@/router.js'

import { ApiService } from '@/services/api.service'
if (process.env.NODE_ENV === 'development') {
    ApiService.init(location.protocol + '//' + location.hostname + ':8080')
} else {
    ApiService.init(constants.API)
}
Vue.prototype.$http = ApiService

import i18n from '@/services/language.service'

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
