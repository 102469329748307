<template>
    <main
        class="flex flex-col w-full mx-auto relative px-4 md:px-0 md:w-main top-mainlow md:top-main"
    >
        <!-- Story-->
        <MainStory></MainStory>
        <!-- FAQ-->
        <MainFaq></MainFaq>
        <!-- Partner-->
        <MainPartner></MainPartner>
    </main>
</template>

<script>
import MainFaq from '@/components/Main/MainFaq'
import MainStory from '@/components/Main/MainStory'
import MainPartner from '@/components/Main/MainPartner'

export default {
    name: 'Main',
    components: {
        MainStory,
        MainFaq,
        MainPartner,
    },
    mounted() {
        //Adding class ".routing" to an "<a> Tag" in the FAQ descriptions, enbables vue to navigate with interal vue router
        let descriptionRoutes = document.querySelectorAll('.routing')
        descriptionRoutes.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                this.$router.push(el.pathname).catch(() => {
                    console.log('Router failed')
                })
            })
        })
    },
}
</script>

<style></style>
