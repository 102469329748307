<template>
    <h3 class="h3">
        <slot></slot>
    </h3>
</template>

<script>
export default {
    name: 'ContentH3',
}
</script>
<style>
.h3 {
    @apply text-lg font-bold mt-4 mb-1;
}
</style>