<template>
    <h2 class="h2">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'ContentH2',
}
</script>
<style>
.h2 {
    @apply text-xl font-bold my-1;
}
</style>