var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_vm._m(0),_c('section',{ref:"faq",staticClass:"flex flex-col items-center w-full bg-gradient-r-main py-8"},[_vm._m(1),_c('div',{staticClass:"flex flex-col items-center w-11/12 md:w-content"},[_c('ul',{staticClass:"flex flex-col text-center sm:flex-row w-full items-center justify-between text-gray4 text-16 sm:text-20 py-4"},[_c('li',{staticClass:"block rounded-6 py-2 px-4 w-full sm:w-auto my-2",class:[
                        _vm.state === 1
                            ? 'border-solid border border-primary'
                            : 'border-solid border border-gray4' ]},[_c('a',{class:[_vm.state === 1 ? 'active' : ''],on:{"click":function($event){_vm.state = 1}}},[_vm._v("cybo Kunden")])]),_c('li',{staticClass:"block rounded-6 py-2 px-4 w-full sm:w-auto my-2",class:[
                        _vm.state === 2
                            ? 'border-solid border border-primary'
                            : 'border-solid border border-gray4' ]},[_c('a',{class:[_vm.state === 2 ? 'active' : ''],on:{"click":function($event){_vm.state = 2}}},[_vm._v("Kassenfachhandel")])]),_c('li',{staticClass:"block rounded-6 py-2 px-4 w-full sm:w-auto my-2",class:[
                        _vm.state === 3
                            ? 'border-solid border border-primary'
                            : 'border-solid border border-gray4' ]},[_c('a',{class:[_vm.state === 3 ? 'active' : ''],on:{"click":function($event){_vm.state = 3}}},[_vm._v("Endkunden")])])]),_c('hr',{staticClass:"bg-GSBFC8D5 border-0 h-2 w-full rounded-3"})]),(_vm.state === 1)?_c('div',{staticClass:"flex flex-col items-center"},_vm._l((_vm.faq1),function(faq){return _c('FaqItem',{key:faq.id,attrs:{"titel":faq.titel,"description":faq.description}})}),1):_vm._e(),(_vm.state === 2)?_c('div',{staticClass:"flex flex-col items-center"},_vm._l((_vm.faq2),function(faq){return _c('FaqItem',{key:faq.id,attrs:{"titel":faq.titel,"description":faq.description}},[_vm._v(" >")])}),1):_vm._e(),(_vm.state === 3)?_c('div',{staticClass:"flex flex-col items-center"},_vm._l((_vm.faq3),function(faq){return _c('FaqItem',{key:faq.id,attrs:{"titel":faq.titel,"description":faq.description}})}),1):_vm._e()]),_vm._m(2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{staticClass:"w-full relative top-1"},[_c('img',{attrs:{"src":require("@/assets/svg/zettel.svg"),"alt":"receipt tear-off edges"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"flex flex-col items-center w-full"},[_c('h2',{staticClass:"text-center text-36 text-BS002E62",attrs:{"id":"faq"}},[_vm._v("FAQ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{staticClass:"w-full relative -top-1"},[_c('img',{attrs:{"src":require("@/assets/svg/zettel_down.svg"),"alt":"receipt tear-off edges"}})])}]

export { render, staticRenderFns }