<template>
    <div id="app" class="flex flex-col bg-background min-h-screen antialiased">
        <Navigation></Navigation>
        <router-view id="router-view-container" :key="$route.fullPath" class="flex-auto mt-12">
        </router-view>
        <Footer></Footer>
        <CookieConsent :key="$route.name"></CookieConsent>
    </div>
</template>

<script>
import CookieConsent from '@/components/CookieConsent'
import Navigation from '@/components/NavBar/NavBar'
import Footer from '@/components/Footer/Footer'
import { loadAppMetaInfos } from '@/content/metaInfos'

export default {
    name: 'App',
    meta() {
        return {
            title: 'Ihr digitaler Kassenbon',
            titleTemplate: 'cybo - %s',
            meta: loadAppMetaInfos(),
        }
    },
    components: {
        Footer,
        Navigation,
        CookieConsent,
    },
}
</script>

<style>
@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('./assets/NunitoSans/NunitoSans-Regular.ttf');
    font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-Light';
    src: url('./assets/NunitoSans/NunitoSans-Light.ttf');
    font-style: normal;
}
body,
html {
    @apply text-base leading-6 min-h-full;
}
#app {
    font-family: NunitoSans-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #000000 !important;
    width: 100%;
}
/*Routerlink underline*/
#router-view-container {
    background: linear-gradient(62deg, #2a6dcb, #0097e4 46.44%, #00bad8 97.88%);
}
.router-link-active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    /*background: theme('colors.primary');*/
    width: 100%;
    height: 2px;
    border-radius: 2px;
}
</style>
