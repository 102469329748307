<template>
    <div>
        <!--Order Wrapper-->
        <section
            class="flex flex-col items-center w-11/12 md:w-main mx-auto mt-8 mb-12 bg-gradient-r-main rounded-3"
            style="margin-top: 100px"
        >
            <div class="w-10/12 md:w-content divide-y-2">
                <header class="flex flex-col items-center w-full pt-2">
                    <img src="@/assets/svg/cybo-logo_style3.svg" alt="" class="my-4" />
                </header>

                <section v-if="state === 0">
                    <p class="text-xl text-black py-8">
                        Vielen Dank, dass Sie sich für cybo interessieren.
                    </p>
                    <p class="text-left font-nunito-light text-xl pb-8">
                        Ihre Abfrage ist nicht mit einem Kaufvertrag verbunden. Nach ihrer
                        Bestellung werden wir, falls vorhanden, mit ihrem Kassenfachhändler in
                        Kontakt treten. Falls Sie keinen Fachhändler haben sollten, vermitteln wir
                        Sie gerne an einen Fachhandelspartner, der die Installation des cybo-Systems
                        für Sie übernimmt.
                    </p>
                </section>

                <section v-else-if="state === 1">
                    <p class="text-xl text-black py-8">Vielen Dank für Ihre Anfrage!</p>
                    <p class="text-left font-nunito-light text-xl pb-8">
                        Wir werden uns zeitnah bei Ihnen melden.
                        <br />
                        <br />
                        Ihr <span class="text-primary">cybo</span> Team.
                    </p>
                </section>

                <section v-else-if="state === 2">
                    <p class="text-xl text-black py-8">Ups!.</p>
                    <p class="text-left font-nunito-light text-xl pb-8">
                        Leider ist ein Fehler aufgetreten.
                        <br />
                        <br />
                        Bitte versuchen Sie es später noch einmal oder schreiben Sie uns direkt
                        unter
                        <a href="mailto:info@cybo.de" class="text-primary hover:underline"
                            >info@cybo.de</a
                        >
                    </p>
                </section>

                <form
                    v-if="state === 0"
                    id="contact-form"
                    class="flex flex-col w-full items-center text-left divide-y-2"
                    @submit.prevent="sendContactRequest"
                >
                    <!-- Section 1 -->
                    <section class="w-full py-8">
                        <h2 class="text-2xl">Kontakt</h2>

                        <div class="flex flex-col w-full justify-between">
                            <!-- Row 1 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="fname">Vorname*</label>
                                    <input
                                        id="fname"
                                        v-model="firstName"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="fname"
                                        required
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="nname">Nachname*</label>
                                    <input
                                        id="nname"
                                        v-model="lastName"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="nname"
                                    />
                                </div>
                            </div>
                            <!-- Row 2 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="email">E-Mail*</label>
                                    <input
                                        id="email"
                                        v-model="email"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="telefon">Telefonnummer*</label>
                                    <input
                                        id="telefon"
                                        v-model="phone"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="telefon"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Section 2 -->
                    <section class="w-full py-8">
                        <h2 class="text-2xl">Ihre Geschäftsadresse</h2>
                        <div class="flex flex-col w-full justify-between">
                            <!-- Row 1 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-full">
                                    <label class="pt-2" for="firmenname">Firmenname*</label>
                                    <input
                                        id="firmenname"
                                        v-model="companyName"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="firmenname"
                                        required
                                    />
                                </div>
                            </div>

                            <!-- Row 2 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-10/12 pr-2">
                                    <label class="pt-2" for="strasse">Straße*</label>
                                    <input
                                        id="strasse"
                                        v-model="companyStreet"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="strasse"
                                        required
                                    />
                                </div>
                                <div class="flex flex-col w-2/12 pl-2">
                                    <label class="pt-2" for="plz">PLZ*</label>
                                    <input
                                        id="plz"
                                        v-model="companyPostalCode"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        pattern="[0-9]{5}"
                                        name="plz"
                                        required
                                    />
                                </div>
                            </div>
                            <!-- Row 3 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="ort">Ort*</label>
                                    <input
                                        id="ort"
                                        v-model="companyCity"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="ort"
                                        required
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="land">Land*</label>
                                    <input
                                        id="land"
                                        v-model="companyCountry"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="land"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="w-full py-8">
                        <h2 class="text-2xl">Ihr Kassensystem</h2>
                        <p class="py-1 text-lg">
                            Um Ihnen den besten Service bieten zu können, teilen Sie uns bitte mit,
                            welches Kassensystem Sie im Einsatz haben.
                        </p>

                        <div class="flex flex-col w-full justify-between">
                            <!-- Row 1 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-full pr-2">
                                    <label class="pt-2" for="hersteller">Hersteller*</label>
                                    <input
                                        id="hersteller"
                                        v-model="systemProducer"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="hersteller"
                                        required
                                    />
                                </div>
                            </div>
                            <!-- Row 2 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="version">Version</label>
                                    <input
                                        id="version"
                                        v-model="systemVersion"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="version"
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="spezifikation">Spezifikation</label>
                                    <input
                                        id="spezifikation"
                                        v-model="systemSpecs"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="spezifikation"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="w-full py-8">
                        <h2 class="text-2xl">Ihr Kassenhändler</h2>
                        <p class="py-1 text-lg">
                            Unsere Zusammenarbeit mit dem Kassenfachhandel garantiert Ihnen eine
                            reibungslose Installation.
                        </p>

                        <div class="flex flex-col w-full justify-between">
                            <!-- Row 1 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="kfirmenname">Firmenname</label>
                                    <input
                                        id="kfirmenname"
                                        v-model="dealerName"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="kfirmenname"
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="ansprechpartner"
                                        >Ansprechpartner</label
                                    >
                                    <input
                                        id="ansprechpartner"
                                        v-model="dealerPartner"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="ansprechpartner"
                                    />
                                </div>
                            </div>
                            <!-- Row 2 -->
                            <div class="flex flex-row w-full py-2">
                                <div class="flex flex-col w-1/2 pr-2">
                                    <label class="pt-2" for="knummer">Telefonnummer</label>
                                    <input
                                        id="knummer"
                                        v-model="dealerPhone"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="text"
                                        name="knummer"
                                    />
                                </div>
                                <div class="flex flex-col w-1/2 pl-2">
                                    <label class="pt-2" for="kemail">E-Mail Adresse</label>
                                    <input
                                        id="kemail"
                                        v-model="dealerEmail"
                                        class="px-2 border-solid border border-in_border h-12 rounded-6"
                                        type="email"
                                        name="kemail"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="w-full py-8">
                        <div class="flex flex-col w-full justify-between">
                            <button
                                type="submit"
                                form="contact-form"
                                class="w-full h-12 rounded-30 cursor-pointer bg-BS002E62 hover:bg-primary text-white duration-200"
                            >
                                <div v-if="isLoading">
                                    <svg
                                        class="animate-spin h-5 w-5 text-white mx-auto"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                                <div v-else>Anfrage abschicken</div>
                            </button>
                        </div>
                    </section>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'OrderPage',
    meta() {
        return {
            title: 'Produktanfrage',
        }
    },
    data() {
        return {
            // flag if button shows loading animation
            isLoading: false,
            // 0 = default, 1 = ok, 2 = error
            state: 0,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            companyName: '',
            companyStreet: '',
            companyPostalCode: '',
            companyCity: '',
            companyCountry: '',
            systemProducer: '',
            systemVersion: '',
            systemSpecs: '',
            dealerName: '',
            dealerPartner: '',
            dealerPhone: '',
            dealerEmail: '',
        }
    },
    methods: {
        sendContactRequest: function () {
            this.isLoading = true

            let context = this
            this.$http
                .post('/website/contactBuy', {
                    firstName: context.firstName,
                    lastName: context.lastName,
                    email: context.email,
                    phone: context.phone,
                    companyName: context.companyName,
                    companyStreet: context.companyStreet,
                    companyPostalCode: context.companyPostalCode,
                    companyCity: context.companyCity,
                    companyCountry: context.companyCountry,
                    systemProducer: context.systemProducer,
                    systemVersion: context.systemVersion,
                    systemSpecs: context.systemSpecs,
                    dealerName: context.dealerName,
                    dealerPartner: context.dealerPartner,
                    dealerPhone: context.dealerPhone,
                    dealerEmail: context.dealerEmail,
                })
                .then(function (response) {
                    context.isLoading = false
                    if (response.status === 200) {
                        context.state = 1
                    } else {
                        context.state = 2
                    }
                    context.firstName = ''
                    context.lastName = ''
                    context.email = ''
                    context.phone = ''
                    context.companyName = ''
                    context.companyStreet = ''
                    context.companyPostalCode = ''
                    context.companyCity = ''
                    context.companyCountry = ''
                    context.systemProducer = ''
                    context.systemVersion = ''
                    context.systemSpecs = ''
                    context.dealerName = ''
                    context.dealerPartner = ''
                    context.dealerPhone = ''
                    context.dealerEmail = ''
                })
                .catch(function (err) {
                    console.log(err)
                    context.isLoading = false
                    context.state = 2
                })
        },
    },
}
</script>

<style scoped></style>
