<template>
    <section class="content-layout" :class="[...customClass]">
        <slot></slot>
    </section>
</template>

<script>
export default {
    name: 'ContentLayout',
    props: {
        customClass: { type: String, required: false, default: '' },
    },
}
</script>

<style>
.content-layout {
    @apply flex flex-col items-center mx-auto max-w-6xl px-4 pb-8;
}
</style>

