<template>
    <footer class="flex-shrink-0 bg-white">
        <!-- Footer Wrapper-->
        <div
            class="m-2 text-center flex flex-col xl:flex-row sm:justify-evenly sm:items-center sm:max-w-6xl sm:mx-auto"
        >
            <!-- Left -->
            <div class="mx-auto py-2 xl:py-0 w-5/6 md:w-2/3 xl:w-1/2">
                <ul
                    class="sm:flex sm:flex-row sm:items-center sm:justify-around sm:h-10 text-BS002E62"
                >
                    <li class="py-1">
                        <router-link to="/datenschutz" class="footer__link relative"
                            >Datenschutz</router-link
                        >
                    </li>

                    <li class="py-1">
                        <a
                            id="cookie-link"
                            class="footer__link relative cursor-pointer"
                            @click="removeCookie"
                        >
                            Cookies
                        </a>
                    </li>

                    <li class="py-1">
                        <a
                            id="faq-link"
                            class="footer__link relative cursor-pointer"
                            @click="routeToFaq"
                        >
                            FAQ
                        </a>
                    </li>

                    <li class="py-1">
                        <router-link to="/impressum" class="footer__link relative"
                            >Impressum</router-link
                        >
                    </li>
                </ul>
            </div>

            <!--Right-->
            <Newsletter :key="$route.name"></Newsletter>
        </div>
    </footer>
</template>

<script>
import Newsletter from './Newsletter'

export default {
    name: 'Footer',

    components: {
        Newsletter,
    },
    methods: {
        routeToFaq() {
            // use scroll if on same page to avoid router error
            if (this.$route.name === 'index') {
                const el = document.getElementById('faq')
                if (el) {
                    el.scrollIntoView()
                }
            } else {
                // use the router if not on index page
                this.$router.push({ name: 'index', hash: '#faq' })
            }
        },
        removeCookie() {
            this.$cookies.remove(this.$CONSENT)
            window.location.href = '/'
        },
    },
}
</script>

<style scoped>
.footer__link:hover {
    text-decoration: none;
}
.footer__link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: theme('colors.secondary');
    width: 0;
    height: 2px;
    border-radius: 2px;
    transition: width 0.2s ease-out;
}
.footer__link:hover::before {
    width: 100%;
}
</style>
