export const loadAppMetaInfos = () => {
    return [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },

        { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        { name: 'robots', content: 'index, follow' },
    ]
}

export const loadMainMetaInfos = () => {
    return [
        {
            name: 'description',
            content:
                'Ermöglichen Sie mit cybo eine digitale Alternative zu Belegen auf Thermopapier, sparen Sie Ressourcen und tun Sie gleichzeitig etwas Gutes für die Umwelt!',
        },
    ]
}

export const loadOpenGraphInfo = () => {
    return [
        {
            property: 'og:title',
            content: 'cybo - Ihr digitaler Kassenbon',
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:description',
            content:
                'Ermöglichen Sie mit cybo eine digitale Alternative zu Belegen auf Thermopapier, sparen Sie Ressourcen und tun Sie gleichzeitig etwas Gutes für die Umwelt!',
        },
        {
            property: 'og:url',
            content: 'https://cybo.de',
        },
        {
            property: 'og:image',
            content: 'https://cybo.de/logos/OpenGraph.jpg',
        },
        { property: 'og:site_name', content: 'cybo' },
        { property: 'og:type', content: 'website' },
    ]
}
export const loadTwitterInfo = () => {
    return [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@cybo_de' },
        { name: 'twitter:title', content: 'cybo - Ihr digitaler Kassenbon' },
        { name: 'twitter:domain', content: 'cybo.de' },
        { name: 'twitter:url', content: 'https://cybo.de' },
        {
            name: 'twitter:description',
            content:
                'Ermöglichen Sie mit cybo eine digitale Alternative zu Belegen auf Thermopapier, sparen Sie Ressourcen und tun Sie gleichzeitig etwas Gutes für die Umwelt!',
        },
        {
            name: 'twitter:image',
            content: 'https://cybo.de/logos/TwitterCard.jpg',
        },
    ]
}
