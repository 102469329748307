<template >
    <article class="py-8">
        <h2 class="text-20 md:text-22 text-BS002E62 font-nunito-regular my-2">
            {{ headline }}
        </h2>
        <p class="text-16 md:text-18 text-gray1 font-nunito-light" v-html="text">{{ text }}</p>
    </article>
</template>

<script>
export default {
    name: 'MainArticle',
    props: {
        headline: { type: String, required: true },
        text: { type: String, required: true },
    },
}
</script>
<style></style>