<template>
    <router-link
        tag="button"
        :to="routing"
        class="w-auto rounded-30 mr-4 text-white xl:text-xl px-10 py-4 my-2"
        :class="[styling === 'primary' ? primaryClass : secondaryClass]"
    >
        <slot></slot>
    </router-link>
</template>


<script>
export default {
    name: 'CtaBtn',
    props: {
        routing: { type: String, required: true },
        styling: { type: String, required: true },
    },
    data() {
        return {
            primaryClass: 'bg-BS002E62',
            secondaryClass: 'bg-BS002E62 hover:bg-BS2A6DCB duration-200',
        }
    },
}
</script>
<style></style>
