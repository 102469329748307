<template>
    <div>
        <!--Preise Container-->
        <ContentLayout>
            <PageHeadline>Preise & Vertrieb</PageHeadline>
            <PageSubHeadline>
                Bestellen Sie Ihre Komplettlösung für den digitalen Kassenzettel direkt bei uns
            </PageSubHeadline>
            <!--Cards Container-->
            <div class="flex flex-col lg:flex-row items-center justify-around w-full my-8">
                <!--Left-->
                <PricingCard :headline="'jährlich'" :price="'16,90€'" :light="true"></PricingCard>
                <!--Right-->
                <!--
            <PricingCard :headline="'monatlich'" :price="'18,90€'" :light="false"></PricingCard>
            --></div>
        </ContentLayout>
    </div>
</template>

<script>
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentLayout from '@/components/Layout/ContentLayout'
import PageSubHeadline from '@/components/Layout/PageSubHeadline'
import PricingCard from '@/components/PricingCard'

export default {
    name: 'Preise',
    components: {
        PricingCard,
        PageSubHeadline,
        ContentLayout,
        PageHeadline,
    },
}
</script>

<style></style>
