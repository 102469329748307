<template >
    <div
        class="rounded-6 px-4 py-4 my-2"
        :class="[light ? 'bg-g text-BS002E62' : 'bg-kontakt text-gray7']"
    >
        <div>
            <h3 class="text-3xl text-center mt-1 mb-4">Paket cybo-system</h3>

            <p class="text-left py-2">
                <span :class="[light ? 'text-p2' : 'text-gray7']">Alle</span> Funktionen im Paket
                enthalten
                <br />
                <span :class="[light ? 'text-p2' : 'text-gray7']">unbegrenzte Anzahl</span>
                gespeicherter Quittungen
                <br />
                weitere Informationen sowie Preis auf Anfrage
            </p>
        </div>

        <hr :class="[light ? 'bg-gray2' : 'bg-gray3']" class="h-2 my-2" />

        <div class="py-2">
            <h4 class="py-2 text-center text-2xl">Software</h4>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">
                    -
                    <span :class="[light ? 'text-p2' : 'text-gray7']">monatliches</span>
                    Serviceabonnement
                </p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- unbegrenzte Anzahl generierter Kassenbelege</p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- Account im cybo-Kundenportal</p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- Bonarchiv</p>
            </div>
        </div>

        <hr :class="[light ? 'bg-gray2' : 'bg-gray3']" class="h-2 my-2" />

        <div class="py-2">
            <h4 class="py-2 text-center text-2xl">Hardware</h4>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">
                    -
                    <span :class="[light ? 'text-p2' : 'text-gray7']">einmalige</span>
                    Anschaffungskosten
                </p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- Touchscreen</p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- cybo-Box</p>
            </div>
            <div class="flex flex-row justify-between items-baseline py-1">
                <p class="text-base">- Netzteil zur Stromversorgung</p>
            </div>
        </div>

        <hr :class="[light ? 'bg-gray2' : 'bg-gray3']" class="h-2 my-2" />

        <div class="py-2">
            <h4 class="py-2 text-center text-2xl">Installation</h4>
            <p :class="[light ? 'text-BS002E62' : 'text-gray7']" class="text-base text-left mb-1">
                Installationskosten werden durch Ihren Fachhändler erhoben.
            </p>
        </div>
        <router-link
            to="produktanfrage"
            class="py-2 mt-4 text-center text-xl block rounded-30"
            :class="[
                light
                    ? 'text-gray7 bg-BS002E62 hover:border-primary hover:bg-primary duration-200'
                    : 'border-solid border border-primary text-gray7 hover:bg-primary duration-200',
            ]"
            >Jetzt anfragen</router-link
        >
    </div>
</template>

<script>
export default {
    name: 'PricingCard',
    props: {
        price: { type: String, required: true },
        headline: { type: String, required: true },
        light: { type: Boolean, required: true },
    },
}
</script>
<style></style>