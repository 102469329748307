<template>
    <div>
        <Hero></Hero>
        <Main></Main>
    </div>
</template>

<script>
import Hero from '@/components/HeroPrimary'
import Main from '@/components/Main/Main'

import { loadMainMetaInfos, loadOpenGraphInfo, loadTwitterInfo } from '@/content/metaInfos'

export default {
    name: 'MainPage',
    meta() {
        return {
            meta: [...loadMainMetaInfos(), ...loadOpenGraphInfo(), ...loadTwitterInfo()],
        }
    },

    components: {
        Hero,
        Main,
    },
}
</script>

<style></style>