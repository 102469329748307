import { render, staticRenderFns } from "./ProduktAnfragePage.vue?vue&type=template&id=bd4aa638&scoped=true&"
import script from "./ProduktAnfragePage.vue?vue&type=script&lang=js&"
export * from "./ProduktAnfragePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd4aa638",
  null
  
)

export default component.exports