<template>
    <div>
        <ContentLayout>
            <PageHeadline>Wir sind Ihr <span class="text-primary">cybo</span>-Team</PageHeadline>
            <div
                class="grid gap-4 grid-cols-1 max-w-md md:grid-cols-2 md:max-w-3xl xlg:grid-cols-3 xlg:max-w-6xl mx-auto"
            >
                <TeamCard
                    v-for="p in team"
                    :key="p.id"
                    :name="p.name"
                    :position="p.position"
                    :functions="p.functions"
                    :img-name="p.imgName"
                ></TeamCard>
            </div>
        </ContentLayout>
    </div>
</template>

<script>
import TeamCard from '@/components/TeamCard'
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentLayout from '@/components/Layout/ContentLayout'

export default {
    name: 'Ueberuns',
    meta() {
        return {
            title: 'Über uns',
        }
    },
    components: {
        ContentLayout,
        PageHeadline,
        TeamCard,
    },
    data() {
        return {
            team: [
                {
                    id: 1,
                    name: 'Joel Daon',
                    position: 'CEO',
                    functions: 'allgemeine Geschäftsstrategie & Leitung',
                    imgName: 'JD_web.jpg',
                },
                {
                    id: 2,
                    name: 'Samuel Klein',
                    position: 'Softwareentwicklung',
                    functions: 'DevOps und Full Stack Entwickler',
                    imgName: 'SK_web.jpg',
                },
                {
                    id: 3,
                    name: 'Yannick Weber',
                    position: 'Softwareentwicklung',
                    functions: 'Technische Umsetzung und Embedded Entwickler',
                    imgName: 'YW_web.jpg',
                },
                {
                    id: 4,
                    name: 'Juan Gómez-García',
                    position: 'Management',
                    functions: 'Hardware Design & Management',
                    imgName: 'JG_web.jpg',
                },
                {
                    id: 5,
                    name: 'Maximilian Rauh',
                    position: 'UI & UX',
                    functions: 'Design Umsetzung',
                    imgName: 'MR_web.jpg',
                },
                {
                    id: 6,
                    name: 'Ricarda Jocher',
                    position: 'UI & UX',
                    functions: 'Design Umsetzung',
                    imgName: 'RJ_web.jpg',
                },
            ],
        }
    },
}
</script>
<style></style>
