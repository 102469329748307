<template>
    <h1 class="text-2xl text-gray9 mt-10 mb-4">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: 'PageHeadline',
}
</script>
<style></style>