<template>
    <header class="fixed top-0 w-full px-4 bg-white py-2 sm:py-0 z-50">
        <!-- w-1024 -->
        <div class="sm:flex max-w-6xl mx-auto items-center justify-between">
            <!--Responsive Wrapper-->
            <div class="flex items-center justify-between">
                <!-- Left -->
                <div>
                    <router-link class="logo" to="/" @click.native="scrollToTop">
                        <img
                            alt="cybo-logo"
                            class="h-8 mt-1"
                            src="@/assets/svg/cybo-logo_style3.svg"
                        />
                    </router-link>
                </div>

                <!-- Right -->
                <div class="sm:hidden">
                    <button
                        type="button"
                        class="block text-g hover:text-white focus:outline-none"
                        @click="isOpen = !isOpen"
                    >
                        <svg class="h-6 w-6" viewBox="0 0 24 24">
                            <path
                                fill-rule="evenodd"
                                fill="#002E62"
                                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <!-- Navlist -->

            <nav :class="isOpen ? 'block' : 'hidden'" class="px-2 sm:block">
                <ul
                    class="flex flex-col text-center justify-between sm:flex sm:flex-row sm:w-nav sm:items-center sm:justify-between"
                >
                    <Navdropdown class="my-2 sm:my-0"></Navdropdown>
                    <li class="my-2 sm:my-0 sm:py-3">
                        <router-link
                            to="preise-und-vertrieb"
                            class="relative text-g text-BS002E62 sm:inline-block"
                            >Preise & Vertrieb</router-link
                        >
                    </li>
                    <li class="my-2 sm:my-0 sm:py-3">
                        <router-link
                            to="ueber-uns"
                            class="relative text-g text-BS002E62 sm:inline-block"
                            >Über Uns</router-link
                        >
                    </li>
                    <li class="my-2 sm:my-0 sm:py-3">
                        <router-link
                            to="kontakt"
                            class="relative text-g text-BS002E62 sm:inline-block"
                            >Kontakt</router-link
                        >
                    </li>
                </ul>
            </nav>

            <!-- Language-Switch -->

            <LangDropdown :class="isOpen ? 'block' : 'hidden'" class="invisible"></LangDropdown>
        </div>
    </header>
</template>

<script>
import LangDropdown from './LangDropdown'
import Navdropdown from './Navdropdown'

export default {
    name: 'Navigation',
    components: {
        LangDropdown,
        Navdropdown,
    },

    data() {
        return {
            isOpen: false,
        }
    },
    watch: {
        $route() {
            this.isOpen = false
        },
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
li:hover a:after{
  content:""; 
  float:left; 
  background:green; 
  width:100%; 
  height:2px; 
  //border-radius: 3px;
}*/
header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
::v-deep a:hover {
    text-decoration: none;
}
::v-deep a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: theme('colors.primary');
    width: 0;
    height: 2px;
    border-radius: 2px;
    transition: width 0.2s ease-out;
}
::v-deep a:hover::before {
    width: 100%;
}
.logo:after {
    width: 0% !important;
}
</style>
