<template>
    <div
        class="py-3 sm:py-1 sm:flex flex flex-col items-center text-g"
        @click.prevent="toggleDropdown()"
    >
        <a
            href="#"
            class="arrow relative h-6 focus:outline-none"
            v-text="currentLanguage.toUpperCase()"
        ></a>

        <ul
            :class="state ? 'sm:flex sm:flex-col' : 'hidden'"
            class="py-2 sm:py-0 sm:justify-evenly sm:bg-g sm:w-12 sm:absolute sm:rounded-3 sm:top-top"
        >
            <li
                v-for="(lang, index) in languages"
                :key="lang.title"
                class="block text-current hover:text-white sm:mx-auto sm:w-6 sm:py-0"
                :class="[index === 0 ? ['sm:pt-3', 'sm:pb-1'] : ['sm:pt-1', 'sm:pb-3']]"
                @click="changeLanguage(lang.language)"
            >
                <a href="#" class="text-g sm:text-gray1 hover:no-underline relative">{{
                    lang.title
                }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'LangDropdown',
    data() {
        return {
            /* which faq is shown */
            state: false,
            currentLanguage: 'en',
            languages: [
                {
                    flag: 'gb',
                    language: 'en',
                    title: 'EN',
                },
                {
                    flag: 'de',
                    language: 'de',
                    title: 'DE',
                },
            ],
        }
    },

    mounted() {
        document.addEventListener('click', this.close)
        //console.log("hilang");
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
        //console.log("ciaolang");
    },
    methods: {
        changeLanguage(langCode) {
            this.currentLanguage = langCode
            console.log(langCode)
        },
        toggleDropdown() {
            this.state = !this.state
        },
        close(e) {
            //console.log("closelang");
            if (!this.$el.contains(e.target)) {
                this.state = false
            }
        },
    },
}
</script>

<style></style>