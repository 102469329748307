import axios from 'axios'

const ApiService = {
    init: function (baseURL) {
        axios.defaults.baseURL = baseURL
    },
    get: function (url) {
        return axios.get(url)
    },
    post: function (url, body) {
        return axios.post(url, body)
    },
    getBaseUrl: function () {
        return axios.defaults.baseURL
    },
}

export { ApiService }
