<template>
    <li
        class="flex flex-col items-center text-g sm:py-3 cursor-pointer"
        @mouseover="isMobile ? (hover = true) : ''"
        @mouseleave="isMobile ? (hover = false) : ''"
        @click.prevent="!isMobile ? (hover = true) : ''"
    >
        <div
            class="arrow flex relative text-BS002E62 h-6 focus:outline-none items-center"
            :class="{
                'router-link-active': subIsActive(['/produktinformation', '/technischedetails']),
            }"
        >
            Produkt<span class="px-1"
                ><img src="../../assets/svg/arrow_down.svg" alt="dropdown arrow"
            /></span>
        </div>
        <ul
            v-if="hover"
            id="nav-dropdown"
            class="sm:justify-evenly bg-white sm:w-auto sm:absolute sm:rounded-3 sm:top-top"
        >
            <li class="block text-BS002E62 hover:text-white py-2 sm:pt-3 sm:pb-1 sm:px-4">
                <router-link
                    to="produktinformation"
                    class="relative text-BS002E62 hover:no-underline"
                    >Produktinformation</router-link
                >
            </li>
            <li class="block text-current hover:text-white py-1 sm:pb-3 sm:pt-1 sm:px-4">
                <router-link
                    to="produktinformation#technische-details"
                    class="relative text-BS002E62 hover:no-underline"
                    >Technische Details</router-link
                >
            </li>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'Navdropdown',
    //el: '.dropdown-item',
    data() {
        return {
            state: false,
            hover: false,
            isMobile: false,
        }
    },
    mounted() {
        document.addEventListener('click', this.close)
    },
    created() {
        window.addEventListener('resize', this.mq)
        this.mq()
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.mq)
        document.removeEventListener('click', this.close)
    },
    methods: {
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input]
            return paths.some((path) => {
                return this.$route.path.indexOf(path) === 0 // current path starts with this path string
            })
        },
        //Check Viewport width to enable hover
        mq() {
            this.isMobile = window.matchMedia('(min-width: 640px)').matches
        },
        close(e) {
            //console.log("closelang");
            if (!this.$el.contains(e.target)) {
                this.state = false
            }
        },
    },
}
</script>

<style>
/*
.arrow::before {
  content:'';
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right:0.5rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}*/
/*
.arrow::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
*/
.router-link-active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: theme('colors.primary');
    width: 100%;
    height: 0;
    border-radius: 2px;
}
#nav-dropdown {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
