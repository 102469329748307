import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//Pages
import MainPage from '@/pages/MainPage'
import UeberUnsPage from '@/pages/UeberUnsPage'
import KontaktPage from '@/pages/KontaktPage'
import TechnischeDetailsPage from '@/pages/TechnischeDetailsPage'
import PreiseUndVertriebPage from '@/pages/PreiseUndVertriebPage'
import ProduktInformationPage from '@/pages/ProduktInformationPage'
import ProduktAnfragePage from '@/pages/ProduktAnfragePage'
import Impressum from '@/pages/Impressum'
import Datenschutz from '@/pages/Datenschutz'

//When adding new routes, also include them in the vue.config.js
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'index',
            component: MainPage,
        },
        {
            path: '/kontakt',
            name: 'kontakt',
            component: KontaktPage,
        },
        {
            path: '/ueber-uns',
            name: 'ueber-uns',
            component: UeberUnsPage,
        },
        {
            path: '/technischedetails',
            name: 'technischedetails',
            component: TechnischeDetailsPage,
        },
        {
            path: '/preise-und-vertrieb',
            name: 'preise-und-vertrieb',
            component: PreiseUndVertriebPage,
        },
        {
            path: '/produktinformation',
            name: 'produktinformation',
            component: ProduktInformationPage,
        },
        {
            path: '/produktanfrage',
            name: 'produktanfrage',
            component: ProduktAnfragePage,
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: Impressum,
        },
        {
            path: '/datenschutz',
            name: 'datenschutz',
            component: Datenschutz,
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    },
})

//Can not be used right now
export const routes = function () {
    let routes = []
    for (let item of router.options.routes) {
        if (item.path !== '*') {
            routes.push(item.path)
        }
    }
    return routes
}

export default router
