<template>
    <div class="h-full w-11/12 md:w-full pt-6">
        <div class="flex flex-col items-center">
            <header
                class="flex flex-row items-center justify-between w-full md:w-content"
                @click="isOpen = !isOpen"
            >
                <h3 class="flex-1 text-left text-gray2 py-2 text-18 md:text-20 hover:underline">
                    {{ titel }}
                </h3>
                <button class="w-4 ml-3 text-S00856D">
                    <img
                        src="@/assets/svg/icon_chevron_down.svg"
                        alt=""
                        class="duration-200"
                        :class="chevronClass"
                    />
                </button>
            </header>
        </div>
        <transition name="fade">
            <div
                v-if="isOpen"
                class="w-11/12 md:w-content px-4 py-4 font-nunito-light text-left text-16 md:text-18"
            >
                <p v-html="description">{{ description }}}</p>
            </div>
        </transition>
        <hr class="bg-GSBFC8D5 mt-5 border-0 h-2 w-full rounded-3" />
    </div>
</template>


<script>
export default {
    name: 'FaqItem',
    props: {
        titel: { type: String, required: true },
        description: { type: String, required: true },
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        chevronClass: function () {
            return this.isOpen ? 'transform rotate-180' : ''
        },
    },
}
</script>


<style scoped>
button:focus {
    outline: none;
}
h3 {
    cursor: pointer;
}
.fade-enter-active {
    transition: opacity 0.5s;
}
.fade-leave-active {
    transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
