<template>
    <div class="w-full">
        <figure class="w-full relative top-1">
            <img src="@/assets/svg/zettel.svg" alt="receipt tear-off edges" />
        </figure>
        <section ref="faq" class="flex flex-col items-center w-full bg-gradient-r-main py-8">
            <header class="flex flex-col items-center w-full">
                <h2 id="faq" class="text-center text-36 text-BS002E62">FAQ</h2>
            </header>

            <div class="flex flex-col items-center w-11/12 md:w-content">
                <ul
                    class="flex flex-col text-center sm:flex-row w-full items-center justify-between text-gray4 text-16 sm:text-20 py-4"
                >
                    <li
                        :class="[
                            state === 1
                                ? 'border-solid border border-primary'
                                : 'border-solid border border-gray4',
                        ]"
                        class="block rounded-6 py-2 px-4 w-full sm:w-auto my-2"
                    >
                        <a :class="[state === 1 ? 'active' : '']" @click="state = 1">cybo Kunden</a>
                    </li>
                    <li
                        :class="[
                            state === 2
                                ? 'border-solid border border-primary'
                                : 'border-solid border border-gray4',
                        ]"
                        class="block rounded-6 py-2 px-4 w-full sm:w-auto my-2"
                    >
                        <a :class="[state === 2 ? 'active' : '']" @click="state = 2"
                            >Kassenfachhandel</a
                        >
                    </li>
                    <li
                        :class="[
                            state === 3
                                ? 'border-solid border border-primary'
                                : 'border-solid border border-gray4',
                        ]"
                        class="block rounded-6 py-2 px-4 w-full sm:w-auto my-2"
                    >
                        <a :class="[state === 3 ? 'active' : '']" @click="state = 3">Endkunden</a>
                    </li>
                </ul>
                <hr class="bg-GSBFC8D5 border-0 h-2 w-full rounded-3" />
            </div>

            <div v-if="state === 1" class="flex flex-col items-center">
                <FaqItem
                    v-for="faq in faq1"
                    :key="faq.id"
                    :titel="faq.titel"
                    :description="faq.description"
                >
                </FaqItem>
            </div>
            <div v-if="state === 2" class="flex flex-col items-center">
                <FaqItem
                    v-for="faq in faq2"
                    :key="faq.id"
                    :titel="faq.titel"
                    :description="faq.description"
                >
                    ></FaqItem
                >
            </div>
            <div v-if="state === 3" class="flex flex-col items-center">
                <FaqItem
                    v-for="faq in faq3"
                    :key="faq.id"
                    :titel="faq.titel"
                    :description="faq.description"
                >
                </FaqItem>
            </div>
        </section>
        <figure class="w-full relative -top-1">
            <img src="@/assets/svg/zettel_down.svg" alt="receipt tear-off edges" />
        </figure>
    </div>
</template>

<script>
import FaqItem from '@/components/Main/MainFaqItem'
import { faq1, faq2, faq3 } from '@/content/faq'

export default {
    name: 'MainFaq',
    components: {
        FaqItem,
    },
    data() {
        return {
            /* which faq is shown */
            state: 1,
            faq1: faq1,
            faq2: faq2,
            faq3: faq3,
        }
    },
}
</script>
<style scoped>
.active {
    /*color: theme("textColor.gray2");*/
    @apply text-BS002E62;
}

a {
    cursor: pointer;
}
a:hover {
    @apply text-BS002E62;
}
</style>