<template>
    <div>
        <!--Produkt Container-->
        <ContentLayout>
            <PageHeader>Produktinformation</PageHeader>

            <div
                class="flex text-left order-1 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <button
                    class="h-12 px-4 py-1 rounded-30 bg-BS002E62 hover:bg-primary active:bg-secondary"
                    type="submit"
                    @click="downloadCyboFlyer"
                >
                    <div>Produktinformationsblatt herunterladen</div>
                </button>
            </div>

            <!--Row 1-->
            <div
                class="flex order-2 items-center text-center lg:items-stretch lg:order-none flex-col lg:flex-row w-full justify-around lg:mt-8"
            >
                <!--Tile 1-->
                <div class="tile flex flex-row w-10/12 md:w-2/3 lg:w-5/12 my-4 lg:my-0">
                    <!--Left-->
                    <div class="w-1/2 bg-white rounded-l-6">
                        <h2 class="text-2xl underline text-BS002E62 py-4">Touchscreen</h2>
                        <figure class="px-4 py-8">
                            <img src="../assets/svg/tablet.svg" alt="SVG mit img-Tag laden" />
                        </figure>
                    </div>
                    <!--Right-->
                    <div class="flex items-center w-1/2 text-left bg-white rounded-r-6 px-4 py-4">
                        <p class="text-BS002E62">
                            Über den Touchscreen kann der Endkunde mit dem System interagieren. Der
                            digitale Kassenzettel wird angezeigt und gleichzeitig erscheint ein
                            QR-Code. Bei Bedarf kann der Endkunde den Kassenbon auf Papier
                            ausdrucken lassen.
                        </p>
                    </div>
                </div>

                <!--Tile 2-->
                <div class="tile flex flex-row w-10/12 md:w-2/3 lg:w-5/12 my-4 lg:my-0">
                    <!--Left-->
                    <div class="w-1/2 bg-white rounded-l-6">
                        <h2 class="text-2xl underline text-BS002E62 py-4">cybo-Box</h2>
                        <figure class="px-4 py-8">
                            <img src="../assets/svg/cybo_box.svg" alt="Cybo Box" />
                        </figure>
                    </div>
                    <!--Right-->
                    <div class="flex items-center w-1/2 text-left bg-white rounded-r-6 px-4 py-4">
                        <p class="text-BS002E62">
                            Die integrierte cybo-Box ist das Herzstück des cybo-Systems. Sie
                            digitalisiert die Belege und verbindet ihr Kassensystem mit dem
                            cybo-Service.
                        </p>
                    </div>
                </div>
            </div>

            <!--Row 2-->
            <div
                class="flex order-1 text-center lg:order-none flex-col items-center md:items-stretch md:flex-row w-9/12 md:my-8"
            >
                <!--Col 1-->
                <div class="flex flex-col w-full md:w-4/12 justify-between">
                    <div
                        class="hidden lg:block h-8 border border-solid border-t-0 border-r-0"
                    ></div>
                    <div class="text-left text-base text-white py-16">
                        Das cybo-System ist ein eigenständiger digitaler Belegdrucker. Es wird wie
                        ein herkömmlicher Netzwerkdrucker in Ihr bestehendes Kassensystem
                        integriert.
                    </div>
                    <div class="hidden lg:block h-8 border-t border-dashed"></div>
                </div>

                <!--Col 2-->
                <div class="flex flex-col w-1/2 md:w-4/12 items-center justify-between">
                    <h2 class="text-2xl underline text-white">cybo-System</h2>
                    <figure class="px-4 py-4 h-full w-full">
                        <img src="../assets/svg/system_no_nfc.png" alt="cybo-System" />
                    </figure>
                </div>

                <!--Col 3-->
                <div class="flex flex-col w-full md:w-4/12 justify-between">
                    <div
                        class="hidden lg:block h-8 border border-solid border-t-0 border-l-0"
                    ></div>
                    <div class="text-left text-base text-white py-16">
                        Es verbindet alle Komponenten miteinander und ist einfach zu konfigurieren.
                        <br />
                        <br />Der Endkunde hat die Möglichkeiten: <br />&#183; Beleg digital aufs
                        Handy zu übertragen <br />&#183; Beleg wie gewohnt auszudrucken
                    </div>
                    <div class="hidden lg:block h-8 border-t border-dashed"></div>
                </div>
            </div>

            <!--Row 3-->
            <div
                class="flex order-2 text-center items-center lg:items-stretch lg:order-none flex-col lg:flex-row w-full justify-around mb-4"
            ></div>
            <!--Row 4-->
            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <h2 class="text-2xl underline">cybo-Service</h2>
                <p class="py-4">
                    Der cybo-Service digitalisiert Ihre Kassenbelege und stellt sie Ihren Kunden in
                    digitaler Form zum Download bereit.
                    <br />
                    <br />
                    Im cybo-Kundenportal können Sie Ihre Belege im Archiv jederzeit wieder abrufen.
                    <br />
                    <br />
                    Um Zugriff auf den Kassenbeleg zu erhalten, benötigt der Endkunde weder eine App
                    noch einen Account.
                </p>
            </div>

            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <img src="../assets/images/cybo_display_smartphone_small.png" alt="cybo-System" />
            </div>

            <!--Row 5-->
            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <h2 class="text-2xl underline">Voraussetzungen</h2>
                <p class="py-4">
                    Um das cybo-System bei Ihnen zu installieren benötigen Sie nur:
                    <br />
                    <br />
                    - Ein Kassensystem mit einem verknüpfbaren, externen Bondrucker<br />
                    - Ein Kassennetzwerk mit Internetanschluss<br />
                    <br />
                    Bisherige Bondrucker können über LAN, USB oder serieller Schnittstelle an das
                    cybo-System angeschlossen werden. Somit entfällt der Kassenbon nicht komplett
                    und kann auf Wunsch des Kunden weiterhin ausgedruckt werden.
                </p>
            </div>

            <!--Row 5-->
            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <h2 class="text-2xl underline">Technische Details</h2>
                <a id="technische-details"></a>
                <p class="py-4"></p>

                <table class="table-auto">
                    <thead>
                        <tr>
                            <th>LCD-Panel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border">
                            <td class="p-2">Bildschirmdiagonale (inch / cm)</td>
                            <td class="p-2">10,1 / 25,65</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Aktiver Bereich Display (cm)</td>
                            <td class="p-2">27,70 x 13,60</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Seitenverhältnis</td>
                            <td class="p-2">16:10</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Physikalische Auflösung</td>
                            <td class="p-2">1280 x 800</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Maximal darstellbare externe Auflösung</td>
                            <td class="p-2">1920 x 1080</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Angezeigte Farben</td>
                            <td class="p-2">16,7 M</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Helligkeit (cd/m^2)</td>
                            <td class="p-2">350</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Kontrast</td>
                            <td class="p-2">700 / 1</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Typische Reaktionszeit Tr / Tf (ms)</td>
                            <td class="p-2">10 / 15</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Betrachtungswinkel horiz. / vertikal (°)</td>
                            <td class="p-2">170 / 170</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Hintergrundbeleuchtung</td>
                            <td class="p-2">LED</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table-auto mt-20">
                    <thead>
                        <tr>
                            <th>PC System</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border">
                            <td class="p-2">CPU</td>
                            <td class="p-2">Allwinner® V40 QuadCore</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Chipset</td>
                            <td class="p-2">CortexTM A7</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Grafikchip</td>
                            <td class="p-2">ARM Mali400 Mp2, unterstützt OpenGL ES 2.0/1.1</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Audiochip</td>
                            <td class="p-2">
                                Integriertes HiFi 100 dB Audio Codec Dual analog mic
                            </td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Arbeitsspeicher</td>
                            <td class="p-2">2GB DD3</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Speicher</td>
                            <td class="p-2">8GB EMMC Flash</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Netzwerk</td>
                            <td class="p-2">10 / 100 MBit Network</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table-auto mt-20">
                    <thead>
                        <tr>
                            <th>Operation / Mechanical</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border">
                            <td class="p-2">Betriebstemperatur (°C)</td>
                            <td class="p-2">-10 ~ +60</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Luftfeuchtigkeitsbereich (RH)</td>
                            <td class="p-2">10% - 90%</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Nettogewicht (kg)</td>
                            <td class="p-2">2,10</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Bruttogewicht (kg)</td>
                            <td class="p-2">2,90 (inklusive Kartonage)</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Gehäusematerial</td>
                            <td class="p-2">Silikonrand, ABS-Kunststoff Rückgehäuse</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Gehäuse (mm) L x H x B</td>
                            <td class="p-2">278,0 x 203,6 x 33,3</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Ausschnitt für Montageklammern (mm) L x H</td>
                            <td class="p-2">264,0 x 180,0</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Max. Wandstärke für Montageklammern (mm)</td>
                            <td class="p-2">5,0</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Anzahl benötigter Klammern</td>
                            <td class="p-2">4</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Befestigung (Rückseite)</td>
                            <td class="p-2">VESA 100</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table-auto mt-20">
                    <thead>
                        <tr>
                            <th>Stromverbrauch</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border">
                            <td class="p-2">Betriebsanzeige</td>
                            <td class="p-2">Grüne LED</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Energieversorgung</td>
                            <td class="p-2">100-240V ACDC active switching; 12V DC-Ausgang</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Betriebsspannung (V)</td>
                            <td class="p-2">8 - 18</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Stromverbrauch (W)</td>
                            <td class="p-2">11</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Stand-By Stromverbrauch (W)</td>
                            <td class="p-2">&lt;1</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table-auto mt-20">
                    <thead>
                        <tr>
                            <th>Touch Panel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border">
                            <td class="p-2">Touch-Technologie</td>
                            <td class="p-2">kapazitiver 10-Punkte Multi-Touch</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Touch-Anschluss</td>
                            <td class="p-2">USB</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Lebenszeit Touch-Panel</td>
                            <td class="p-2">Unbegrenzt</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Oberflächenhärte</td>
                            <td class="p-2">7H</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Oberflächenbehandlung</td>
                            <td class="p-2">Entspiegelt (chemisch geäzt)</td>
                        </tr>
                        <tr class="border">
                            <td class="p-2">Glashärtung</td>
                            <td class="p-2">Chemisch gehärtet</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <h2 class="text-2xl mb-2">Technische Zeichnung</h2>
                <img src="../assets/images/faytech_technische-zeichnung.png" alt="cybo-System" />
            </div>

            <div
                class="flex text-left order-4 lg:items-stretch lg:order-none flex-col w-9/12 justify-around my-8 text-white"
            >
                <button
                    class="h-12 px-4 py-1 rounded-6 rounded-30 bg-BS002E62 hover:bg-primary active:bg-secondary"
                    type="submit"
                    @click="downloadCyboFlyer"
                >
                    <div>Produktinformationsblatt herunterladen</div>
                </button>
            </div>
        </ContentLayout>
    </div>
</template>

<script>
import PageHeader from '@/components/Layout/PageHeadline'
import ContentLayout from '@/components/Layout/ContentLayout'

import { ApiService } from '@/services/api.service'

export default {
    name: 'Produktinformation',
    components: { ContentLayout, PageHeader },
    meta() {
        return {
            title: 'Produktinformation',
        }
    },
    methods: {
        downloadCyboFlyer: function () {
            const pdfPath = document.location.origin + '/pdf/cybo_10-1.pdf'
            window.open(pdfPath, '_blank').focus()
        },
    },
}
</script>

<style scoped>
.tile {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
